<template>
    <div class="logout-view ViewWrapper">
        <clv-head-meta :title="$t('Cloutlayer')"></clv-head-meta>
        <div class="container">
            <div class="row">
                <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">

                    <common-box-logo></common-box-logo>

                    <!-- BODY -->
                    <div class="gk-card gk-card--core-style-1 mb-5">
                        <core-loader :with-padding="true"></core-loader>
                    </div>

                    <common-box-footer></common-box-footer>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { AccountService } from '../common/services/api.service';

export default {
    name: 'LogoutSilentView',
    created () {
        AccountService.logout().then(() => {}).catch((reason) => {}).finally(() => {
            // Flush account (reset store).
            this.$store.dispatch('account/flush');
            // @help : Cookies are removed by server.
            // Redirect to home.
            window.location.replace('/');
        });
    }
};
</script>
